import useSWR from 'swr'
import Api from '../../services/Api'
import { popularProductURL } from '../../ApiEndpoints'

function useGetPopularProducts() {
  const getKey = () => {
    return `${popularProductURL}`
  }

  const { data, error } = useSWR(() => getKey(), getPopularProducts)

  const isLoading = !data && !error
  const popularProducts =
    (data
      ? data?.['popular']?.map((item) => {
          const {
            id,
            image,
            price,
            name,
            retail_price,
            sale_price,
            custom_url,
            brand_name,
          } = item
          return {
            id,
            bigcommerce_id: id,
            image,
            price: price,
            name,
            retail_price: retail_price,
            sale_price: sale_price,
            custom_url,
            brand_name,
          }
        })
      : []) || []

  return {
    popularProducts,
    isLoading,
  }
}

async function getPopularProducts(url) {
  const response = await Api.get(url)
  return response.data.data
}

export default useGetPopularProducts
