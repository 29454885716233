import React from "react";
import Slider from "react-slick";
import ProductCard from "../../components/products/ProductCard";
import PropTypes from "prop-types";
import { product_view_slider_settings } from "../../../config/slider";

function ProductsList({ title, subTitle, products, disableBg }) {
  return products.length ? (
    <div
      className={`product-list-block page-block ${!disableBg && "section-with-bg"
        }`}
    >
      <div className="container">
        {title && (
          <h2 className="section-title text-center">
            {`${title}`}

            {/* {subTitle && <p className="font-formal">{`${subTitle}`}</p>} */}
          </h2>
        )}

        {
          <div className="product-list">
            {products?.length > 5 ? (
              <Slider
                {...product_view_slider_settings}
                className={"slider-wrapper"}
              >
                {products.map((product) => {
                  product["bigcommerce_id"] = product.id;
                  return <ProductCard key={product.id} product={product} />;
                })}
              </Slider>
            ) : (
              // <Slider
              //   {...product_view_slider_settings}
              //   className={"slider-wrapper"}
              // >
              <>
                {products.map((product) => {
                  product["bigcommerce_id"] = product.id;
                  return <ProductCard key={product.id} product={product} />;
                })}
              </>
              // </Slider>
            )}
          </div>
        }
      </div>
    </div>
  ) : null;
}

ProductsList.propTypes = {
  disableBg: PropTypes.bool,
  products: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default React.memo(ProductsList);
