import React from "react";

function Tab({ activeTab, label, onClick }) {
  return (
    (label ?
    <li
      className={
        activeTab === label
          ? "tab-list-active tab-list-item"
          : label === ""
          ? null
          : "tab-list-item"
      }
      onClick={() => onClick(label)}
    >
      {label}
    </li>
    : null)
  );
}

export default Tab;
