import React from "react";
// import Tabs from "../../components/form/tabs/Tabs";
import ProductsList from "./ProductsList";
import PropTypes from "prop-types";

function RealtedProducts({ relatedProducts }) {
  return relatedProducts?.length ? (
    // <section className="tab-section">
    //   <Tabs>
    //     <div label="Related Products">
    <ProductsList title={"Related Products"} products={relatedProducts} disableBg={true} />
    //     </div>
    //   </Tabs>
    // </section>
  ) : null;
}

RealtedProducts.propTypes = {
  relatedProducts: PropTypes.array,
};

export default React.memo(RealtedProducts);
