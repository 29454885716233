import React, { useEffect, useState } from "react";
import YoutubeEmbed from "../../components/form/YoutubeEmbed";
import PropTypes from "prop-types";
import Image from "../../components/common/Image";

function VideoSection({ videos }) {
  const default_value = videos?.[0]?.video_id || "";
  const [selectedVideo, updateSelectedVideo] = useState(default_value);

  useEffect(() => {
    updateSelectedVideo(videos?.[0]?.["video_id"]);
  }, [videos]);

  return (
    <section className="product-video-section">
      {selectedVideo ? 
        <div className="product-main-video">
          <YoutubeEmbed embedId={selectedVideo} />
        </div> : null}
      <div className="video-list">
        {videos?.map((i) => (
          <div
            className="video-list-item"
            key={i.id}
            onClick={() => updateSelectedVideo(i.video_id)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateSelectedVideo(i.video_id);
              }
            }}
          >
            <span>
              <Image
                src={`//i.ytimg.com/vi/${i.video_id}/default.jpg`}
                alt={`youtube video id ${i.video_id}`}
              />
            </span>
            {/* <p>{i.title}</p> */}
          </div>
        ))}
      </div>
    </section>
  );
}

VideoSection.propTypes = {
  videos: PropTypes.array,
};

export default VideoSection;
