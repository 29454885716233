import { useState } from "react";
import Api from "../../services/Api";
import useToastify from "../ui/useToastify";

export default function useAskQuestionWithoutLogin() {
  const [isLoading, setLoading] = useState(false);
  const { toastMessage } = useToastify();

  const askQuestionWithoutLogin = async (data) => {
    try {
      setLoading(true);
      const res = await askQuestionservice(data);
      setLoading(false);
      if (res?.status === 200) {
        toastMessage("success", "Mail sent successfully.");
      } else {
        toastMessage("error", "Something went wrong. please try again");
      }
      return res;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    askQuestionWithoutLogin,
    isLoading,
  };
}

async function askQuestionservice(data) {
  const url = "/customer-poc/message/guest-user";
  const response = await Api.post(url, data);
  return response;
}
