import React from "react";
import AskQuestionModal from "../../components/form/Dialog/AskQuestionModal";
import ReactModal from "../../components/form/Dialog/NewModal";
import useToggle from "../../hooks/useToggle";
import useShowQuestionModal from "../../hooks/products/useAskQuestionModal";

function QuestionSection({ name, productId }) {
  const { closeQuestionModal } = useShowQuestionModal();
  // const modalID = sessionStorage.getItem('questionModal')

  const [isOpen, toggleDialog] = useToggle(false);

  const openAskQuestionModal = () => {
    toggleDialog();
  };

  const handleToggleDialog = () => {
    if (closeQuestionModal) {
      closeQuestionModal();
    }
    toggleDialog();
  };

  return (
    <>
      <div className="pdp-contact-form">
        <span
          className="pdp-contact-form-link"
          onClick={() => openAskQuestionModal()}
        >
          Questions about this Product? Click here to reach your sales Rep
        </span>
      </div>

      {isOpen && (
        <ReactModal
          isOpen={isOpen}
          setIsOpen={() => handleToggleDialog()}
          title={"ASK YOUR QUESTION"}
        >
          <AskQuestionModal
            onClose={toggleDialog}
            name={name}
            productId={productId}
          />
        </ReactModal>
      )}
    </>
  );
}


export default QuestionSection;
