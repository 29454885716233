import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, preview }) => {
  return (
    <div className={preview && "product-video-item"}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string,
  preview: PropTypes.bool,
};

export default YoutubeEmbed;
