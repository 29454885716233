import { useState } from 'react'
import Api from '../../services/Api'
import useToastify from '../ui/useToastify'

export default function useAskQuestion() {  
  const [isLoading, setLoading] = useState(false)
  const { toastMessage } = useToastify()

  const askQuestion = async (data) => {
    try {
      setLoading(true)
      const res = await askQuestionservice(data)
      setLoading(false)
      if (res?.status === 200) {
        toastMessage('success', 'Mail sent successfully.')
      } else {
        toastMessage("error", "Something went wrong. please try again");
      }    
      return res
    } catch (e) {
      console.error(e)
    }
  }

  return {
    askQuestion,
    isLoading,
  }
}

async function askQuestionservice(data) {
    const url = '/customer-poc/message'
  const response = await Api.post(url, data)
  return response?.data
}
