import React, { useRef } from "react";
import "./modal.css";
import { useFormik } from "formik";
import {
  ask_question_validation_schema,
  ask_question_without_login__validation_schema,
} from "../../../utils/askQuestion";
import TextField from "../TextField";
import TextArea from "../TextArea";
import Button from "../button/Button";
import { getFormError } from "../../../utils/form";
import useShowQuestionModal from "../../../hooks/products/useAskQuestionModal";
import useAskQuestion from "../../../hooks/askQuestion/useAskQuestion";
import { isUserLoggedIn } from "../../../utils/auth";
import useAskQuestionWithoutLogin from "../../../hooks/askQuestion/useAskQuestionWithoutLogin";
import ReCAPTCHA from "react-google-recaptcha";
import useToastify from "../../../hooks/ui/useToastify";

export default function AskQuestionModal({ onClose, name, productId }) {
  const { closeQuestionModal } = useShowQuestionModal();
  const { askQuestion, isLoading } = useAskQuestion();
  const { askQuestionWithoutLogin } = useAskQuestionWithoutLogin();
  const user = isUserLoggedIn();
  const reCaptchaRef = useRef();
  const { toastMessage } = useToastify();

  const formik = useFormik({
    initialValues: {
      product_name: name,
      message: "",
      email: "",
      customer_name: "",
    },
    validationSchema: user
      ? ask_question_validation_schema
      : ask_question_without_login__validation_schema,
    onSubmit: (values) => {
      const reCaptchaValue = reCaptchaRef?.current?.getValue();
      if (user || (reCaptchaValue && !user)) {
        if (user) {
          const payload = {
            product_name: values.product_name,
            message: values.message,
            product_id: productId,
          };
          values.product_id = productId;
          askQuestion(payload);
        } else {
          const payload = {
            product_name: values.product_name,
            message: values.message,
            product_id: productId,
            email: user?.email || values.email,
            customer_name: user ? user.name : values.customer_name,
          };
          askQuestionWithoutLogin(payload);
        }
        onClose();
        closeQuestionModal();
        formik.resetForm();
      } else {
        toastMessage("error", "Please Check re-captcha first to submit");
      }
    },
  });

  return (
    <div className="question-modal-wrapper">
      <form id="question-form" onSubmit={formik.handleSubmit}>
        {/* Hidden Product Name */}
        <div hidden>
          <div className="form-field">
            <TextField
              id="product_name"
              label={"Product Name"}
              labelFor={"Product Name"}
              name={"product_name"}
              error={getFormError(formik.errors, "product_name")}
              type={"text"}
              onChange={formik.handleChange}
              touched={formik.touched["product_name"]}
              value={formik.values.product_name}
              readOnly
              disabled
            />
          </div>
        </div>
        {!user && (
          <>
            {/* Name Field */}
            <div className="form-field">
              <TextField
                id="customer_name"
                label={"Name"}
                labelFor={"Name"}
                name={"customer_name"}
                error={getFormError(formik.errors, "customer_name")}
                type={"text"}
                onChange={formik.handleChange}
                touched={formik.touched["customer_name"]}
                value={formik.values.customer_name}
                required
              />
            </div>

            {/* Email Field */}
            <div className="form-field">
              <TextField
                id="email"
                label={"Email"}
                labelFor={"Email"}
                name={"email"}
                error={getFormError(formik.errors, "email")}
                type={"email"}
                onChange={formik.handleChange}
                touched={formik.touched["email"]}
                value={formik.values.email}
                required
              />
            </div>
          </>
        )}

        {/* Message Field */}
        <div className="form-field">
          <TextArea
            id="message"
            label={"Enter your Question *"}
            labelFor={"Enter your Question *"}
            name={"message"}
            error={getFormError(formik.errors, "message")}
            onChange={formik.handleChange}
            touched={formik.touched["message"]}
            value={formik.values.message}
            required
          />
        </div>
        {!user && (
          <div className="gcaptcha-wrapper">
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
            />
          </div>
        )}

        {/* Submit Button */}
        <Button type="submit" className="button" disabled={isLoading}>
          SUBMIT QUESTION
        </Button>
      </form>
    </div>
  );
}
