import * as Yup from 'yup'
import { requiredMsg } from '../utils/form'


export const ask_question_without_login__validation_schema = Yup.object().shape({
  customer_name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

export const ask_question_validation_schema = Yup.object().shape({ 
  message: Yup.string().required(requiredMsg('message')),
})