function useShowQuestionModal() {
    const setQuestionModalVisible = (productID) => {
      sessionStorage.setItem('questionModal', productID)
    }
  
    const closeQuestionModal = () => {
      sessionStorage.removeItem('questionModal')
    }
  
    return {
        setQuestionModalVisible,
        closeQuestionModal,
    }
  }
  
  export default useShowQuestionModal
  