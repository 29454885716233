import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TabNew from "./Tab";
import "./Tabs.css";

function Tabs({ children, activateReview, setActivateReview, setVisibleReviews }) {
  const tabs = children.length ? children : [children];
  const [activeTab, setActiveTab] = useState(tabs[0]?.props?.label);

  useEffect(() => {
    if (activateReview) {
      setActiveTab("Reviews");
    }
  }, [activateReview, tabs]);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    setActivateReview(false);
    setVisibleReviews(10)
  };

  return (
    <div className="tabs">
      <ol className="tab-list">
        {tabs.length
          ? tabs.map((item, index) => {
              const label = item?.props?.label;
              return (
                <TabNew
                  activeTab={activeTab}
                  key={index}
                  label={label}
                  onClick={onClickTabItem}
                />
              );
            })
          : null}
      </ol>
      <div className="tab-content">
        {tabs.length &&
          tabs.map((child) => {
            if (child?.props?.label !== activeTab) return undefined;
            return child?.props?.children;
          })}
      </div>
    </div>
  );
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  activateReview: PropTypes.bool,
  setActivateReview: PropTypes.func,
  setVisibleReviews: PropTypes.func
};

export default Tabs;
