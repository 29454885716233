import React, { useState } from "react";
import Tabs from "../../components/form/tabs/Tabs";
import IconStar from "../../assets/icon_js/IconStar";
import { DATE_FORMAT_PRIMARY, formatDate } from "../../utils/date";
import PropTypes from "prop-types";
import useGetProductReview from "../../hooks/review/useGetProductReview";
import { times } from "../../utils/functions";

function DescriptionSection({
  description,
  warranty,
  productId,
  activateReview,
  setActivateReview,
}) {
  const { data: reviews } = useGetProductReview(productId);
  const [visibleReviews, setVisibleReviews] = useState(10);
  const loadMoreReviews = () => {
    setVisibleReviews(visibleReviews + 10);
    setActivateReview(true);
  };

  // const fieldsToShow = [    
  //   "case qty"    
  // ];

  // filteredCustomFields = filteredCustomFields?.length
  //   ? filteredCustomFields.filter(
  //       (item) => fieldsToShow.includes(item?.name.toLowerCase())
  //     )
  //   : [];
  return (
    <section className="tab-section product-tab-section">
      <Tabs
        activateReview={activateReview}
        setActivateReview={setActivateReview}
        setVisibleReviews={setVisibleReviews}
      >
        {description ? (
          <div label="Description">
            <div
              className="bc-product__description product-description"
              dangerouslySetInnerHTML={{ __html: description || "" }}
            ></div>
          </div>
        ) : (
          <div label=""></div>
        )}
        {warranty ? (
          <div label="Warranty Information">
            <div
              className="bc-product__description product-warranty"
              dangerouslySetInnerHTML={{
                __html: warranty || "Warranty Information Not Found",
              }}
            ></div>
          </div>
        ) : (
          <div label=""></div>
        )}
        {reviews?.length ? (
          <div label="Reviews">
            <div className="review-list">
              {reviews?.length ? (
                reviews.slice(0, visibleReviews).map((review) => {
                  return (
                    <div key={review.id} className="review-item">
                      <div className="review-star">
                        {times(5, (i) => (
                          <span
                            key={i}
                            className={`star-icon ${i < review.rating ? "icon-fill" : "icon-empty"
                              }`}
                          >
                            <IconStar />
                          </span>
                        ))}
                      </div>

                      <h5>{review.title}</h5>
                      <p className="review-date">
                        {`Posted by ${review?.name} on ${formatDate(
                          review.date_created,
                          DATE_FORMAT_PRIMARY
                        )}`}
                        <br />
                      </p>
                      <div className="review-detail">
                        <p>{review.text}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Reviews Not Found</p>
              )}
              {reviews?.length ? (
                visibleReviews < reviews.length && (
                  <div className="review-load-button text-center">
                    <button
                      className="bc-btn button button-small text-uppercase"
                      onClick={loadMoreReviews}
                    >
                      Load More
                    </button>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div label=""></div>
        )}
      </Tabs>
    </section>
  );
}

DescriptionSection.propTypes = {
  description: PropTypes.string,
  warranty: PropTypes.string,
  reviews: PropTypes.array,
  customFields: PropTypes.array,
  activateReview: PropTypes.bool,
  setActivateReview: PropTypes.func,
};

export default DescriptionSection;
